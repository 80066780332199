// src/components/ContactUs.js
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa'; 
import { contactUsApi } from "../../utils/apis";
import "../../Pages/ContactUs/ContactUs.css";

function ContactUs() {
  // const [isContactUsVisible, setContactUsVisible] = useState(false);

  // const handleCloseContactUs = () => {
  //   setContactUsVisible(false);
  // };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[A-Za-z]+$/, "First name should only contain letters")
      .required("First name is required"),
    lastName: Yup.string()
      .matches(/^[A-Za-z]+$/, "Last name should only contain letters")
      .required("Last name is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is required")
      .max(100, 'Email cannot be more than 100 characters'),
    phone: Yup.string()
      .matches(/^\+?[0-9]{7,15}$/, "Enter a valid phone number. It should contain 7 to 15 digits.")
      .required("Phone number is required"),
    message: Yup.string().required("Message is required"),
    agree: Yup.boolean().oneOf([true], "You must accept the privacy policy"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      countryCode: "+91",
      agree: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log("jkhkj");
      try {
        const data = {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          phone: values.phone,
          message: `Genoday Query: ${values.message}`,
        };

        const response = await contactUsApi(data);
        if (response.status === 200) {
          toast.success("Message sent successfully!");
          resetForm();
        } else {
          toast.error("Unable to submit the form. Please try again later.");
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again.");
      }
    },
  });

  return (
    <div>
      <ToastContainer />
      <h1>Contact Us</h1>
      <div className="syntax-contactus">
        <div className="box-1"></div>
        <div className="overlay-navbar-contactUs">
          <div className="contactus-container">
            <div className="upper-section-form">
              {/* <button className="close-button" onClick={handleCloseContactUs}>
              X
            </button> */}

              <p>
                We'd love to hear from you! Reach out with any questions or to
                discuss how we can help transform your digital presence.
              </p>
            </div>
            <form className="contact-us-form" onSubmit={formik.handleSubmit}>
              <div className="Contactus-form-fullname">
                <div className="name-left">
                  <label>First name</label>
                  <input type="text" id="firstName"
                    name="firstName" required value={formik.values.firstName}
                    onChange={formik.handleChange} />
                  {formik.errors.firstName && formik.touched.firstName && (
                    <div className="error">{formik.errors.firstName}</div>
                  )}
                </div>
                <div className="name-right">
                  <label>Last name</label>
                  <input type="text" id="lastName"
                    name="lastName" required value={formik.values.lastName}
                    onChange={formik.handleChange} />
                  {formik.errors.lastName && formik.touched.lastName && (
                    <div className="error">{formik.errors.lastName}</div>
                  )}
                </div>
              </div>
              <div className="Contactus-form-email-phonenumber">
                <div className="email">
                  <label>Email ID</label>
                  <input type="email" id="email"
                    name="email" required value={formik.values.email}
                    onChange={formik.handleChange} />
                  {formik.errors.email && formik.touched.email && (
                    <div className="error">{formik.errors.email}</div>
                  )}
                </div>
                <div className="number">
                  <label for="phoneNumber">Phone number</label>
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    required
                  />
                  {formik.errors.phone && formik.touched.phone && (
                    <div className="error">{formik.errors.phone}</div>
                  )}
                </div>
              </div>
              <div className="message">
                <label>Message:</label>
                <textarea name="message" required value={formik.values.message}
                  onChange={formik.handleChange}></textarea>
              </div>
              <div class="checkbox-container-contactus">
                <input type="checkbox" id="agree"
                  name="agree"
                  checked={formik.values.agree}
                  onChange={formik.handleChange} />
                <div classname="receive-info">
                  I'd like to receive more information about the company. I
                  understand and agree to the{" "}
                  <span className="receive-info-span">Privacy Policy</span>.
                </div>
              </div>
              <div className="submit-cancel-btn-contactus-form">
                {/* <button className="cancel-btn-contactus-form" type="cancel">
                  Cancel
                </button> */}
                <button className="submit-btn submit-btn-contactus-form" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="box-2"></div>
      </div>
      <div className="contact-container">
      <h2 className="contact-header">Contact Us</h2>
      <p className="contact-description">
        Have any questions or need assistance? Reach out to us directly!
      </p>
      <div className="contact-info">
        <p className="contact-item">
          <FaEnvelope className="contact-icon" />{' '}
          <strong>Email:</strong>{' '}
          <a href="mailto:amar932@gmail.com" className="contact-link">connect@genoday.com</a>
        </p>
        <p className="contact-item">
          <FaPhoneAlt className="contact-icon" />{' '}
          <strong>Phone:</strong>{' '}
          <a href="tel:+9178599325" className="contact-link">+917097002759</a>
        </p>
      </div>
    </div>
    </div>
  );
}

export default ContactUs;
